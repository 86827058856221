import * as React from "react";
import { useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, TextField } from "@mui/material";
import { auth, authCheck } from "../api/cocktails";

export default function LoginPage() {
  const { setIsLoggedIn, isLoggedIn } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [password, setPassword] = React.useState("");

  const isMounted = React.useRef(true);

  useEffect(() => {
    const checkAuth = async () => {
      const response: any = await authCheck();
      if (response?.status === 200) {
        setIsLoggedIn(true);
        navigate("/");
      }
      if (response?.status === 401) {
        setIsLoggedIn(false);
      }
    };

    checkAuth();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();

    const response: any = await auth(password);
    if (response?.data.message === "Auth successful") {
      setIsLoggedIn(true);
      navigate("/");
    } else {
      alert("Invalid password");
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <form onSubmit={handleLogin}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <TextField
            type="password"
            label="Password"
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" type="submit">
            Login
          </Button>
        </Box>
      </form>
    </Container>
  );
}
