import axios from "axios";
import _ from "lodash";

const maleconApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 1000,
  withCredentials: true,
});
maleconApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      // Redirect to unauth page
      window.location.href = "/unauth";
    }
    return Promise.reject(error);
  }
);

export function auth(password: string) {
  return maleconApi
    .post("/auth", { password })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error in auth:", error);
    });
}

export function getAllCocktails() {
  return maleconApi
    .get("/cocktails")
    .then((response) => {
      return _.sortBy(response.data, ["name"]);
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function editCocktail(cocktailId: number, updatedCocktail: any) {
  return maleconApi
    .put(`/cocktails/${cocktailId}`, updatedCocktail)
    .then((response) => {
      console.log("Cocktail edited successfully:", response);
      // Additional actions after successful edit
    })
    .catch((error) => {
      console.log("Error editing cocktail:", error);
      // Additional error handling
    });
}

export function createCocktail(newCocktail: any) {
  return maleconApi
    .post("/cocktails", newCocktail)
    .then((response) => {
      console.log("Cocktail created successfully:", response);
      // Additional actions after successful creation
    })
    .catch((error) => {
      console.log("Error creating cocktail:", error);
      // Additional error handling
    });
}

export function authCheck() {
  return maleconApi
    .get("/authcheck")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error in authCheck:", error);
    });
}
