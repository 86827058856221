import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Chip, Divider, Tooltip, Zoom } from "@mui/material";
import _ from "lodash";
import "./cocktailCard.css";
import { Box } from "@mui/material";
import { red } from "@mui/material/colors";

interface CocktailCardProps {
  item: any;
  onClick: () => void;
}

export default function CocktailCard({ item, onClick }: CocktailCardProps) {
  const {
    name,
    notes,
    original_cocktail,
    instructions,
    riff,
    riff_source,
    year,
    recipe,
  } = item;

  const colorMap = {
    bourbon: "#e6d9b7",
    rye: "#c0ae7e",
    rum: "#ba8686",
    gin: "#987898",
    tequila: "#add4ad",
    mezcal: "red",
    vodka: "blue",
  };
  return (
    <Card
      onClick={onClick}
      sx={{
        width: 260,
        height: 260,
        overflow: "auto",
        boxShadow: 4,
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardActionArea
        onClick={onClick}
        style={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          overflow: "auto",
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}{" "}
            {original_cocktail && (
              <Tooltip
                title="Original cocktail"
                placement="top"
                color="primary"
                TransitionComponent={Zoom}
                arrow
              >
                <Chip label="OC" color="primary" size="small" />
              </Tooltip>
            )}
          </Typography>

          <ul>
            {!_.isUndefined(recipe) ? (
              recipe.map((ingredient: any, index) => {
                const { type, unit, amount, preferred_brand } = ingredient;
                return (
                  <li key={index}>
                    {amount} {unit} {type}{" "}
                    {preferred_brand ? `(${preferred_brand})` : ""}
                  </li>
                );
              })
            ) : (
              <li></li>
            )}
          </ul>
          {/* <Box mt={2} mb={2}>
            <Divider />
          </Box> */}
          {/* <Typography variant="body2">{instructions}</Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
