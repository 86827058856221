import React, { useState } from "react";
import {
  Drawer,
  SwipeableDrawer,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
  Box,
  Grid,
  Chip,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useEffect } from "react";
import { editCocktail, createCocktail } from "../api/cocktails";

interface Ingredient {
  amount: string;
  unit: string;
  type: string;
  preferred_brand: string;
}
interface CocktailDrawerProps {
  open: boolean;
  onClose: () => void;
  creatingNew: boolean;
  selectedCocktail: {
    id: number;
    name: string;
    recipe: Ingredient[];
    instructions: string;
    original_cocktail: boolean;
    riff: boolean;
    notes: string;
  };
  updateCocktailMap: (cocktailId: number, updatedCocktail: any) => void;
}

const CocktailDrawer: React.FC<CocktailDrawerProps> = ({
  open,
  onClose,
  selectedCocktail,
  creatingNew,
  updateCocktailMap,
}) => {
  const [editMode, setEditMode] = useState(creatingNew);
  const [modifiedCocktail, setModifiedCocktail] = useState({
    ...selectedCocktail,
    recipe: Array.isArray(selectedCocktail.recipe)
      ? selectedCocktail.recipe
      : [],
  });
  const [additionalIngredients, setAdditionalIngredients] = useState<
    Ingredient[]
  >([]);

  useEffect(() => {
    setModifiedCocktail({
      ...selectedCocktail,
      recipe: Array.isArray(selectedCocktail.recipe)
        ? selectedCocktail.recipe
        : [],
    });
  }, [selectedCocktail]);
  useEffect(() => {
    setEditMode(creatingNew);
  }, [creatingNew]);

  const handleToggleEditMode = () => {
    if (editMode) {
      const updatedCocktail = {
        ...modifiedCocktail,
        recipe: [...(modifiedCocktail?.recipe || []), ...additionalIngredients],
      };
      setModifiedCocktail(updatedCocktail);
      setAdditionalIngredients([]);
      if (creatingNew) {
        createCocktail(updatedCocktail).then((response: any) => {
          updateCocktailMap(response.data.id, response.data);
        });
      } else {
        editCocktail(selectedCocktail.id, updatedCocktail).then((response) => {
          updateCocktailMap(selectedCocktail.id, updatedCocktail);
        });
      }
    }
    setEditMode(!editMode);
  };
  const handleOnClose = () => {
    setAdditionalIngredients([]);
    setEditMode(false);
    onClose();
  };

  const handleIngredientChange = (
    index: number,
    field: keyof Ingredient,
    value: string
  ) => {
    setModifiedCocktail((prevState) => {
      if (prevState) {
        const newRecipe = [...(prevState.recipe || [])];
        newRecipe[index] = {
          ...newRecipe[index],
          [field]: field === "amount" ? parseFloat(value) : value,
        };
        return { ...prevState, recipe: newRecipe };
      }
      return prevState;
    });
  };
  const handleDeleteIngredient = (index: number) => {
    setModifiedCocktail((prevState) => {
      if (prevState) {
        const newRecipe = [...(prevState.recipe || [])];
        newRecipe.splice(index, 1);
        return { ...prevState, recipe: newRecipe };
      }
      return prevState;
    });
  };
  const handleAddIngredient = () => {
    setAdditionalIngredients([
      ...additionalIngredients,
      { type: "", unit: "part", amount: "1", preferred_brand: "" },
    ]);
  };
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={() => {}}
      onClose={handleOnClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <div
        key={modifiedCocktail.id}
        style={{
          width: window.innerWidth >= 1024 ? "50vw" : "80vw",
          padding: "20px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {editMode ? (
            <TextField
              defaultValue={modifiedCocktail.name}
              label="Name"
              fullWidth
              onChange={(e) =>
                setModifiedCocktail((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }
            />
          ) : (
            <Typography variant="h4" gutterBottom>
              {modifiedCocktail.name}
            </Typography>
          )}
          {localStorage.getItem("allowEdit") === "true" && (
            <Button onClick={handleToggleEditMode}>
              {editMode ? "Save" : "Edit"}
            </Button>
          )}
        </Box>
        {editMode ? (
          <Box mt={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={modifiedCocktail.original_cocktail}
                  onChange={() =>
                    setModifiedCocktail((prevState) => ({
                      ...prevState,
                      original_cocktail: !prevState.original_cocktail,
                    }))
                  }
                />
              }
              label="Original Cocktail"
            />
          </Box>
        ) : (
          modifiedCocktail.original_cocktail && (
            <Chip color="primary" label="Original" />
          )
        )}
        <Divider style={{ margin: "20px 0" }} />
        <List>
          {modifiedCocktail.recipe?.map((ingredient, index) => (
            <ListItem key={index}>
              {editMode ? (
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <TextField
                      defaultValue={`${ingredient.amount}`}
                      label="Amount"
                      fullWidth
                      onChange={(e) =>
                        handleIngredientChange(index, "amount", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      defaultValue={`${ingredient.unit}`}
                      label="Unit"
                      fullWidth
                      onChange={(e) =>
                        handleIngredientChange(index, "unit", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      defaultValue={`${ingredient.type}`}
                      label="Type"
                      fullWidth
                      onChange={(e) =>
                        handleIngredientChange(index, "type", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      label="Preferred brand"
                      defaultValue={
                        ingredient.preferred_brand
                          ? `${ingredient.preferred_brand}`
                          : ""
                      }
                      fullWidth
                      onChange={(e) => {
                        handleIngredientChange(
                          index,
                          "preferred_brand",
                          e.target.value
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button onClick={() => handleDeleteIngredient(index)}>
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <ListItemText
                  primary={`${ingredient.amount} ${ingredient.unit} ${
                    ingredient.type
                  }${
                    ingredient.preferred_brand
                      ? ` (${ingredient.preferred_brand})`
                      : ""
                  }`}
                />
              )}
            </ListItem>
          ))}
          {editMode && (
            <div>
              {additionalIngredients.map((ingredient, index) => (
                <ListItem key={index + (modifiedCocktail.recipe?.length || 0)}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={2}>
                      <TextField
                        label="Amount"
                        value={ingredient.amount}
                        fullWidth
                        onChange={(e) => {
                          const newIngredients = [...additionalIngredients];
                          newIngredients[index].amount = e.target.value;
                          setAdditionalIngredients(newIngredients);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <TextField
                        label="Unit"
                        value={ingredient.unit}
                        fullWidth
                        onChange={(e) => {
                          const newIngredients = [...additionalIngredients];
                          newIngredients[index].unit = e.target.value;
                          setAdditionalIngredients(newIngredients);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <TextField
                        label="Type"
                        value={ingredient.type}
                        fullWidth
                        onChange={(e) => {
                          const newIngredients = [...additionalIngredients];
                          newIngredients[index].type = e.target.value;
                          setAdditionalIngredients(newIngredients);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        label="Preferred brand"
                        value={ingredient.preferred_brand}
                        fullWidth
                        onChange={(e) => {
                          const newIngredients = [...additionalIngredients];
                          newIngredients[index].preferred_brand =
                            e.target.value;
                          setAdditionalIngredients(newIngredients);
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button onClick={() => handleDeleteIngredient(index)}>
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
              <ListItem>
                <Button onClick={handleAddIngredient}>Add Ingredient</Button>
              </ListItem>
            </div>
          )}
        </List>
        <Divider style={{ margin: "20px 0" }} />

        {editMode ? (
          <TextField
            defaultValue={modifiedCocktail.instructions}
            fullWidth
            multiline
            // Add onChange handler to update the instructions
            onChange={(e) =>
              setModifiedCocktail((prevState) => {
                if (prevState) {
                  return { ...prevState, instructions: e.target.value };
                }
                return prevState;
              })
            }
          />
        ) : (
          <Typography variant="body1">
            {modifiedCocktail.instructions}
          </Typography>
        )}

        <Divider style={{ margin: "20px 0" }} />

        {editMode ? (
          <TextField
            defaultValue={modifiedCocktail.notes}
            fullWidth
            multiline
            onChange={(e) =>
              setModifiedCocktail((prevState) => {
                if (prevState) {
                  return { ...prevState, notes: e.target.value };
                }
                return prevState;
              })
            }
          />
        ) : (
          <Typography variant="body1">{modifiedCocktail.notes}</Typography>
        )}
      </div>
    </SwipeableDrawer>
  );
};

export default CocktailDrawer;
