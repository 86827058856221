// import React from "react";
import * as React from "react";
import { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthContext } from "./AuthContext";
import LoginPage from "./pages/login";
import UnauthPage from "./pages/unauth";
import { useCookies } from "react-cookie";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
  const { isLoggedIn, setIsLoggedIn } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    if (!!cookies.token) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <Home theme={theme} themeToggle={colorMode.toggleColorMode} />
          }
        />
        <Route path="/unauth" element={<UnauthPage />} />
      </Routes>
    </div>
  );
}
export default function ToggleColorMode() {
  const [cookies] = useCookies(["token"]);
  const storedMode = localStorage.getItem("mode");
  const [isLoggedIn, setIsLoggedIn] = useState(!!cookies.token);
  const [mode, setMode] = React.useState<"light" | "dark">(
    (storedMode as "light" | "dark") || "light"
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = mode === "light" ? "dark" : "light";
        setMode(newMode);
        localStorage.setItem("mode", newMode);
      },
    }),
    [mode]
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
          <Router>
            <App />
          </Router>
        </AuthContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
