import React, { ReactElement, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getAllCocktails } from "../api/cocktails";
import _, { identity } from "lodash";
import CocktailCard from "../components/cocktailCard";
import CocktailDrawer from "../components/cocktailDrawerEditable";
import Grid from "@mui/material/Grid";
import Fuse from "fuse.js";
import { Fab } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

interface Props {
  theme: any;
  themeToggle: () => void;
}
interface Cocktail {
  id: number;
  name: string;
  recipe: Ingredient[];
  instructions: string;
}
interface Ingredient {
  amount: number;
  unit: string;
  type: string;
  preferred_brand: string;
}

const Home: React.FC<Props> = ({ theme, themeToggle }) => {
  const [cocktails, setCocktails] = useState<Cocktail[]>([]);
  const [filteredCocktails, setfilteredCocktails] = useState<Object[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [open, setDetailPaneOpen] = React.useState(false);
  const [creatingNew, setCreatingNew] = React.useState(false);
  const [selectedCocktail, setSelectedCocktail] = React.useState(Object);
  const [filteredIds, setFilteredIds] = React.useState([]);

  const handleClickOpen = (cocktail, createNew: boolean = false) => {
    setCreatingNew(createNew);
    setSelectedCocktail(cocktail);
    setDetailPaneOpen(true);
  };
  const handleClose = () => {
    setDetailPaneOpen(false);
  };
  useEffect(() => {
    getAllCocktails().then((response) => {
      setCocktails(response);
      setfilteredCocktails(response);
      setLoading(false);
    });
  }, [CocktailCard]);
  const fuse = new Fuse(cocktails, {
    keys: ["name", "recipe.type", "recipe.preferred_brand"],
    threshold: 0.4,
  });

  const handleCocktailMapChange = (cocktailId, updatedCocktail) => {
    const updatedCocktails = cocktails.map((cocktail) => {
      if (cocktail.id === cocktailId) {
        return updatedCocktail;
      } else {
        return cocktail;
      }
    });
    setCocktails(updatedCocktails);
    const newFilteredCocktails = _.intersectionBy(
      updatedCocktails,
      filteredCocktails,
      "id"
    );
    // });
    setfilteredCocktails(newFilteredCocktails);
  };
  const uniqueIngredientList = _.uniq(
    _.flattenDeep(_.map(cocktails, (c: any) => _.map(c.recipe, (r) => r.type)))
  );
  const sortedCocktails = _.sortBy(cocktails, ["name"]);
  return (
    <div>
      {isLoading === false ? (
        <div style={{ padding: 20 }}>
          {/* {cocktails} */}
          <Stack className="flex flex-col space-y-4" sx={{}}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={sortedCocktails}
                  getOptionLabel={(item: any) => {
                    return _.get(item, "name", "Errawr");
                  }}
                  onChange={(event, newInputValue: any[]) => {
                    const filteredIds = _.map(
                      newInputValue,
                      (cocktail: any) => {
                        return cocktail.id;
                      }
                    );
                    if (_.isEmpty(filteredIds)) {
                      setfilteredCocktails(cocktails);
                    } else {
                      setfilteredCocktails(
                        _.reduce(
                          cocktails,
                          (acc: Object[], val: any, key) => {
                            if (filteredIds.includes(val.id)) {
                              acc.push(val);
                            }
                            return acc;
                          },
                          []
                        )
                      );
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search for cocktails here"
                      placeholder="Cocktails"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Autocomplete
                  multiple
                  freeSolo
                  id="tags-outlined"
                  options={uniqueIngredientList.sort()}
                  getOptionLabel={(ingredient: any) => {
                    return ingredient;
                  }}
                  onChange={(event, newInputValue) => {
                    const tempFilteredCocktails = cocktails.filter(
                      (cocktail: any) => {
                        return cocktail.recipe.some((ingredient) => {
                          return newInputValue.includes(ingredient.type);
                        });
                      }
                    );
                    const filteredIds = _.map(
                      tempFilteredCocktails,
                      (cocktail: any) => {
                        return cocktail.id;
                      }
                    );
                    if (_.isEmpty(filteredIds)) {
                      setfilteredCocktails(cocktails);
                    } else {
                      setfilteredCocktails(
                        _.reduce(
                          cocktails,
                          (acc: Object[], val: any, key) => {
                            if (filteredIds.includes(val.id)) {
                              acc.push(val);
                            }
                            return acc;
                          },
                          []
                        )
                      );
                    }
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search for ingredients here"
                      placeholder="Ingredients"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={10} lg={4}>
                <TextField
                  id="outlined-basic"
                  label="Search everything here"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setfilteredCocktails(cocktails);
                    } else if (e.target.value === "allowEdit") {
                      localStorage.setItem("allowEdit", "true");
                    } else {
                      const filteredCocktails = _.map(
                        fuse.search(e.target.value),
                        (item) => item.item
                      );
                      setfilteredCocktails(filteredCocktails);
                    }
                  }}
                ></TextField>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                xs={2}
                lg={1}
              >
                <IconButton
                  onClick={themeToggle}
                  color="inherit"
                  style={{ marginTop: "24px" }}
                >
                  {theme.palette.mode === "dark" ? (
                    <Brightness7Icon />
                  ) : (
                    <Brightness4Icon />
                  )}
                </IconButton>
              </Grid>
              {/* add a new cocktail button */}
              {localStorage.getItem("allowEdit") === "true" && (
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={() => {
                    setCreatingNew(true);
                    handleClickOpen({}, true);
                  }}
                  style={{
                    position: "fixed",
                    right: "16px",
                    bottom: "16px",
                    zIndex: 1000,
                  }}
                >
                  <AddIcon />
                </Fab>
              )}
            </Grid>
            <Grid container spacing={2}>
              {filteredCocktails.map((cocktail: any) => {
                return (
                  <Grid
                    key={cocktail.id}
                    xs={12}
                    sm={4}
                    md={"auto"}
                    lg={2.4}
                    xl={"auto"}
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CocktailCard
                      item={cocktail}
                      onClick={() => handleClickOpen(cocktail)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </div>
      ) : (
        ""
      )}
      <CocktailDrawer
        open={open}
        onClose={handleClose}
        creatingNew={creatingNew}
        selectedCocktail={selectedCocktail}
        updateCocktailMap={handleCocktailMapChange}
      />
    </div>
  );
};

export default Home;
