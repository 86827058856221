import { Button, Typography, Container } from "@mui/material";

const UnauthPage: React.FC = () => {
  const handleGoToLogin = () => {
    window.location.href = "/login";
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Unauthorized Access
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        You are not authorized to access this page.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoToLogin}>
        Go Back to Login
      </Button>
    </Container>
  );
};

export default UnauthPage;
